import * as Feather from "feather-icons";


export default class SupportComponent {
    constructor(element) {
        this.element = element;
        this._addEventListeners();
    }

    loadSupportPages(endpoint, editEndpoint) {
        // Load the data
        fetch(endpoint)
            .then((response) => {
                return response.json();
            })
            .then((pages) => {
                pages.forEach((supportPage) => {
                    this.addPage(supportPage, editEndpoint);
                });
                // Need to do this because pages might contain icons
                Feather.replace();
            })
            .catch((error) => {
                console.log(error);
            });
    }

    addPage(page, editEndpoint) {
        this.element.innerHTML = "";
        let newPage = document.createElement("div");
        let headerElement = document.createElement("h3");
        let authorElement = document.createElement("span");
        authorElement.classList.add("text-muted");
        let helpContent = document.createElement("div");
        let headerText = document.createTextNode(page.name);
        headerElement.appendChild(headerText);
        if (editEndpoint){
            let editParagraph = document.createElement("p");
            editParagraph.classList.add("mt-1");
            let editAnchor = document.createElement("a");
            let editText = document.createTextNode("Edit this page");
            editAnchor.setAttribute("href", editEndpoint.replace("0", page.id));
            editAnchor.setAttribute("title", "Edit this page");
            editAnchor.appendChild(editText);
            editParagraph.appendChild(editAnchor);
            authorElement.appendChild(editParagraph);
        }
        helpContent.innerHTML = page.content;
        helpContent.classList.add("mt-2");
        newPage.appendChild(headerElement);
        newPage.appendChild(authorElement);
        newPage.appendChild(helpContent);
        this.element.appendChild(newPage);
    }


    _addEventListeners() {
        let helpToggle = document.querySelector("#help-toggle");
        helpToggle.addEventListener("click", (event) => {
            const endpoint = helpToggle.getAttribute("data-source-url");
            const editEndpoint = helpToggle.getAttribute("data-edit-url");
            this.loadSupportPages(endpoint, editEndpoint);
        });
    }

}
